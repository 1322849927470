<template>
  <div :class="[themeClasses]" class="v-testimonial ml-0">
      <v-carousel
          cycle
          :height="$vuetify.breakpoint.mdAndUp ? 200 : 250"
          hide-delimiter-background
          hide-delimiters
          :show-arrows="false"
        >
        <v-carousel-item v-for="(testimonial, index) in testimonials" :key="index">
          <v-sheet color="primary" height="100%">
          <v-row :testimonial="testimonial" :key="index" class="mb-4">
          <v-col
            class="v-testimonial__blurb body-1 white--text"
            cols="12"
            md="10"
            v-text="testimonial.blurb"
          />
          <v-col class="hidden-sm-and-down shrink">
            <v-icon x-large class="ml-n5">
              mdi-format-quote-close
            </v-icon>
          </v-col>
          </v-row>

    <footer :testimonial="testimonial" :key="index">
      <v-divider
        class="white v-testimonial__divider"
      />
      <h4
        class="white--text headline font-weight-medium"
        v-text="testimonial.person"
      />
      <div
        class="white--text headline font-weight-light"
        v-text="testimonial.title"
      />
    </footer>
          </v-sheet>
    </v-carousel-item>
  </v-carousel>
  </div>
</template>

<script>
// Mixins
import Themeable from "vuetify/lib/mixins/themeable";

export default {
  mixins: [Themeable],
  data: () => ({
    testimonials: [
      {
        blurb:
          "Thank you for your ministry to me over these past number of months.  The Lord used you to bring healing, deliverance, and increased freedom to this life.  You are much appreciated!",
        person: "Val",
        title: "",
      },
      {
        blurb:
          "I'm not sure I can fully express my thankfulness for you and for all you mean to my life.  You are a true life line to me.  Jesus has, and continues to use you so amazingly in my life.  Thank you!",
        person: "Chris",
        title: "",
      },
      // {
      //   blurb:
      //     'Thank you from the bottom of my heart for walking with me and for helping me navigate life during this season of my journey".  Thank you for being Gods voice of truth, wisdom, affirmation, life and love during this dark and challenging season.  You are such a gift in my life!',
      //   person: "",
      //   title: "",
      // },
      {
        blurb:
          "Thank you for being available to talk with me a few weeks ago! Your listening ear and encouragement were so appreciated.  I so valued the clarity you provided to better understand the personality we had been dealing with",
        person: "Corinna",
        title: "",
      },
      {
        blurb:
          "Thank you so much for this time.  I feel like I was a little bit of a wreck when I started meeting with you and you've helped so much as I've navigated the waters of healing and seeking God's vision for the next steps of life",
        person: "Ashley",
        title: "Missionary",
      },
    ],
  }),
};
</script>

<style lang="sass">
.v-testimonial
  margin: 0 auto
  max-width: 450px

  .v-testimonial__divider
    max-width: 120px
    border-width: 2px

    .v-testimonial__blurb
      line-height: 2

    &.theme--dark
      color: white
</style>
